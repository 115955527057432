import { Box, Breadcrumbs, Typography, Grid, Button, IconButton, Link, ButtonProps, Card, CardContent, TextField, FormControl, InputLabel, MenuItem, Select, Divider, Stack, Autocomplete, SelectChangeEvent, RadioGroup, Radio, FormControlLabel } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from "react-router-dom";
import authStore from "../../stores/AuthStore";
import DeleteIcon from '@mui/icons-material/Delete';
import { ChangeEvent, useEffect, useRef, useState } from "react";
import projectsStore from "../../stores/ProjectsStore";
import housesStore from "../../stores/HousesStore";
import SimpleBackdrop from "../../components/SimpleBackdrop";
import VisuallyHiddenInput from "../../components/VisuallyHiddenInput";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import PrintIcon from '@mui/icons-material/Print';
import masterDataStore from "../../stores/MasterDataStore";
import DialogConfirm from "../../components/DialogConfirm";
import bookingStore from "../../stores/BookingStore";
import { enqueueSnackbar } from "notistack";
import imagesStore from "../../stores/ImagesStore";
import statusStore from "../../stores/StatusStore";
import ReactToPrint from "react-to-print";
import PrintContract from "../../components/Report/Pdf/PrintContract";
import dayjs, { Dayjs } from "dayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import landStore from "../../stores/LandStore";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { v4 as uuid4 } from 'uuid';
import companyStore from "../../stores/CompanyStore";

const BookingCreate: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const permission: any = authStore.user?.roles.find((role: any) => role.menu.path === '/bookings');
  const [openConfirm, setOpenConfirm] = useState({
    open: false,
    title: "",
    message: "",
    color: 'primary' as ButtonProps['color'],
    type: 'create' as 'create' | 'edit' | 'delete' | 'confirm-duplicate'
  });
  const [project, setProject] = useState('all');
  const [projectData, setProjectData] = useState([]);
  const [house, setHouse] = useState('all');
  const [houseData, setHouseData] = useState([]);
  const [houseDataFilter, setHouseDataFilter] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
  const [images, setImages] = useState<any[]>([]);
  const [deleteImages, setDeleteImages] = useState<any[]>([]);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [idCard, setIdCard] = useState("");
  const [address, setAddress] = useState("");
  const [alley, setAlley] = useState("");
  const [street, setStreet] = useState("");
  const [subDistrict, setSubDistrict] = useState("");
  const [province, setProvince] = useState("");
  const [district, setDistrict] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [provinceData, setProvinceData] = useState<any>([]);
  const [districtData, setDistrictData] = useState<any>([]);
  const [subDistrictData, setSubDistrictData] = useState<any[]>([]);
  const [districtOptions, setDistrictOptions] = useState<any>([]);
  const [subDistrictOptions, setSubDistrictOptions] = useState<any>([]);
  const [tel, setTel] = useState("");
  const [sellingPrice, setSellingPrice] = useState(0);
  const [bookingFee, setBookingFee] = useState(0);
  const [contractFee, setContractFee] = useState(0);
  const [arrearage, setArrearage] = useState(0);
  const [remark, setRemark] = useState("");
  const [provinceValue, setProvinceValue] = useState("");
  const [districtValue, setDistrictValue] = useState("");
  const [subDistrictValue, setSubDistrictValue] = useState("");
  const inputRefs = useRef<HTMLInputElement[]>([]);
  const [bookingNo, setBookingNo] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [statusData, setStatusData] = useState<any[]>([]);
  const [status, setStatus] = useState("");
  const [remarkCancled, setRemarkCancled] = useState("");
  const [occupation, setOccupation] = useState("");
  const [income, setIncome] = useState("");
  const [otherIncome, setOtherIncome] = useState("");
  const [source, setSource] = useState("");
  const printRef = useRef(null);
  const [data, setData] = useState<any>([]);
  const [docDate, setDocDate] = useState<Dayjs | null>(dayjs());
  const [moo, setMoo] = useState("");
  const [gender, setGender] = useState("0");
  const [birthdate, setBirthdate] = useState<Dayjs | null>(dayjs());
  const [bankData, setBankData] = useState<any[]>([]);
  const [statusBankData, setStatusBankData] = useState<any>([]);
  const [landData, setLandData] = useState<any>([]);
  const [bookingBank, setBookingBank] = useState<any[]>([]);
  const [bookingBankOld, setBookingBankOld] = useState<any[]>([]);
  const [reasonsType, setReasonsType] = useState("");
  const [reasonsTypeData, setReasonsTypeData] = useState<any[]>([]);
  const [companyTypes, setCompanyTypes] = useState('company');
  const [companyName, setCompanyName] = useState('');
  const [companyTaxId, setCompanyTaxId] = useState('');
  const [companyAddress, setCompanyAddress] = useState('');
  const [companyAlley, setCompanyAlley] = useState('');
  const [companyStreet, setCompanyStreet] = useState('');
  const [companyMoo, setCompanyMoo] = useState('');
  const [companyProvince, setCompanyProvince] = useState('');
  const [companyProvinceId, setCompanyProvinceId] = useState('');
  const [companySubDistrict, setCompanySubDistrict] = useState('');
  const [companyDistrictId, setCompanyDistrictId] = useState('');
  const [companyDistrict, setCompanyDistrict] = useState('');
  const [companySubDistrictId, setCompanySubDistrictId] = useState('');
  const [companyZipcode, setCompanyZipcode] = useState('');
  const [companyTel, setCompanyTel] = useState('');
  const [districtCompanyOptions, setDistrictCompanyOptions] = useState<any>([]);
  const [subDistrictCompanyOptions, setSubDistrictCompanyOptions] = useState<any>([]);
  const [companyInfo, setCompanyInfo] = useState<any>([]);
  const [firstname2, setFirstname2] = useState("");
  const [lastname2, setLastname2] = useState("");
  const [idCard2, setIdCard2] = useState("");
  const [address2, setAddress2] = useState("");
  const [alley2, setAlley2] = useState("");
  const [street2, setStreet2] = useState("");

  const [zipCode2, setZipCode2] = useState("");
  const [provinceData2, setProvinceData2] = useState<any>([]);
  const [districtData2, setDistrictData2] = useState<any>([]);
  const [subDistrictData2, setSubDistrictData2] = useState<any[]>([]);
  const [districtOptions2, setDistrictOptions2] = useState<any>([]);
  const [subDistrictOptions2, setSubDistrictOptions2] = useState<any>([]);
  const [tel2, setTel2] = useState("");
  const [provinceValue2, setProvinceValue2] = useState("");
  const [districtValue2, setDistrictValue2] = useState("");
  const [subDistrictValue2, setSubDistrictValue2] = useState("");
  const [moo2, setMoo2] = useState("");
  const [gender2, setGender2] = useState("0");
  const [birthdate2, setBirthdate2] = useState<Dayjs | null>(dayjs());
  const [occupation2, setOccupation2] = useState("");
  const [income2, setIncome2] = useState("");
  const [otherIncome2, setOtherIncome2] = useState("");
  const [source2, setSource2] = useState("");

  const addRef = (el: any) => {
    if (el && !inputRefs.current.includes(el)) {
      inputRefs.current.push(el);
    }
  };

  const handleKeyDown = (event: any, index: number, i = 0) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (i === -1) {
        inputRefs.current[23].focus();
      }
      else if (index + 1 < inputRefs.current.length) {
        inputRefs.current[index + 1].focus();
      }
    }
  };
  const handleChangeProject = (event: any) => {
    const _project = projectData.find((project: any) => project?.id === event.target.value);
    setProject(event.target.value);
    setData({ ...data, project: _project });
    const _houseData = houseData.filter((house: any) => house?.project?.id === event.target.value && !house?.bookings.find((booking: any) => booking?.status?.code === "sold"));
    setHouseDataFilter(_houseData);
  };

  const handleChangeReasonsType = (event: any) => {
    setReasonsType(event.target.value as string);
  };

  const handleChangeHouse = (event: any) => {
    setHouse(event.target.value as string);
    const _house = houseData.find((house: any) => house?.id === event.target.value);
    setData({ ...data, house: _house });
    landStore.getByHouseId(event.target.value).then((data: any) => {
      setLandData(data);
    });
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      const newSelectedFiles = Array.from(files);
      setSelectedFiles([...selectedFiles, ...newSelectedFiles]);
    }
  };

  const handleFileRemove = (index: number) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
  };

  const handleFileRemoveImageList = (file: any) => {
    const updatedImages = images.filter((image) => image.id !== file.id);
    setImages(updatedImages);
    setDeleteImages([...deleteImages, file]);
  };

  const handleFileUpload = (refId: string) => {
    if (selectedFiles.length > 0) {
      selectedFiles.forEach(async (file: any, index: number) => {
        const formdata = new FormData();
        formdata.append("refType", "booking");
        formdata.append("refId", refId);
        formdata.append('file', file);
        formdata.append('createBy', authStore.user?.id);
        formdata.append("createAt", new Date(Date.now() + index * 1000).toISOString());

        await imagesStore.upload(formdata)
          .catch(err => console.error("err", err));
      });
    }
  };

  const handleEditFileUpload = async (id: string) => {
    if (selectedFiles.length > 0) {
      selectedFiles.forEach(async (file: any, index: number) => {
        const formdata = new FormData();
        formdata.append("refType", "booking");
        formdata.append("refId", id);
        formdata.append('file', file);
        formdata.append('updateBy', authStore.user?.id);
        formdata.append("updateAt", new Date(Date.now() + index * 1000).toISOString());
        await imagesStore.upload(formdata)
          .catch(err => console.error("err", err));
      });
    }
    if (deleteImages.length > 0) {
      await deleteImages.forEach(async (item: any) => {
        await imagesStore.update(item.id, {
          updateBy: authStore.user?.id,
          updateAt: new Date(),
          isDeleted: true
        }).catch(err => console.error("err deleteImages", err));
      });
    }
  };

  const handleSave = () => {
    try {
      if (!firstname) {
        enqueueSnackbar('กรุณากรอกชื่อลูกค้า', { variant: 'warning' });
        setIsLoading(false);
        return;
      }
      bookingStore.create({
        customer: {
          firstname: firstname,
          lastname: lastname,
          idCard: idCard,
          address: address,
          alley: alley,
          street: street,
          subDistrict: subDistrict || undefined,
          province: province || undefined,
          district: district || undefined,
          zipCode: zipCode,
          tel: tel,
          createBy: authStore.user?.id,
          createAt: new Date(),
          occupation: occupation,
          income: income ? parseFloat(income) : 0,
          otherIncome: otherIncome ? parseFloat(otherIncome) : 0,
          source: source,
          moo: moo,
          gender: gender,
          birthdate: birthdate?.format('YYYY-MM-DD'),
        },
        customer2: {
          ...data.customer2, createBy: authStore.user?.id, income: income2 ? parseFloat(income2) : 0, otherIncome: otherIncome2 ? parseFloat(otherIncome2) : 0, birthdate: birthdate2?.format('YYYY-MM-DD'),
          createAt: new Date(),
        },
        sellingPrice: sellingPrice,
        bookingFee: bookingFee,
        contractFee: contractFee,
        arrearage: arrearage,
        remark: remark,
        project: project !== 'all' ? project : undefined,
        house: house !== 'all' ? house : undefined,
        createBy: authStore.user?.id,
        createAt: new Date(),
        bookingNo: bookingNo,
        status: status || undefined,
        remarkCancled: remarkCancled,
        docDate: docDate?.format('YYYY-MM-DD'),
        reasonsType: reasonsType || undefined,
        companyName: companyName,
        companyTaxId: companyTaxId,
        companyAddress: companyAddress,
        companyAlley: companyAlley,
        companyStreet: companyStreet,
        companyMoo: companyMoo,
        companyDistrict: companyDistrictId !== '' ? companyDistrictId : undefined,
        companyProvince: companyProvinceId !== '' ? companyProvinceId : undefined,
        companySubDistrict: companySubDistrictId !== '' ? companySubDistrictId : undefined,
        companyZipcode: companyZipcode,
        companyTel: companyTel,
        bookingBank: bookingBank.map((item) => {
          return {
            bank: item.bank || undefined,
            loanAmount: item.loanAmount || undefined,
            interest: item.interest || undefined,
            status: item.status || undefined,
            createBy: authStore.user?.id,
            createAt: new Date(),
          };
        })
      }).then((res) => {
        handleFileUpload(res.id);
        if (res.error) {
          enqueueSnackbar(res.error.message, { variant: 'error' });
          setIsLoading(false);
          return;
        } else {
          setIsLoading(false);
          enqueueSnackbar('บันทึกข้อมูลสำเร็จ', { variant: 'success' });
          navigate(`/booking-edit/${res.id}`);
        }
      }).catch((err) => {
        console.error(err);
        setIsLoading(false);
        enqueueSnackbar('บันทึกข้อมูลไม่สำเร็จ', { variant: 'error' });
      });
    } catch (err) {
      console.error(err);
    }
  };
  const handleEdit = () => {
    try {
      if (id) {
        bookingStore.update(id, {
          customer: {
            id: customerId,
            firstname: firstname,
            lastname: lastname,
            idCard: idCard,
            address: address,
            alley: alley,
            street: street,
            subDistrict: subDistrict || undefined,
            province: province || undefined,
            district: district || undefined,
            zipCode: zipCode,
            tel: tel,
            updateBy: authStore.user?.id,
            updateAt: new Date(),
            occupation: occupation,
            income: income ? parseFloat(income) : 0,
            otherIncome: otherIncome ? parseFloat(otherIncome) : 0,
            source: source,
            moo: moo,
            gender: gender,
            birthdate: birthdate?.format('YYYY-MM-DD'),
          },
          customer2: {
            ...data.customer2, updateBy: authStore.user?.id, income: income2 ? parseFloat(income2) : 0, otherIncome: otherIncome2 ? parseFloat(otherIncome2) : 0, birthdate: birthdate2?.format('YYYY-MM-DD'),
            updateAt: new Date(),
          },
          sellingPrice: sellingPrice,
          bookingFee: bookingFee,
          contractFee: contractFee,
          arrearage: arrearage,
          remark: remark,
          project: project !== 'all' ? project : undefined,
          house: house !== 'all' ? house : undefined,
          updateBy: authStore.user?.id,
          updateAt: new Date(),
          bookingNo: bookingNo,
          status: status !== "" ? status : undefined,
          remarkCancled: remarkCancled,
          docDate: docDate?.format('YYYY-MM-DD'),
          reasonsType: reasonsType || undefined,
          companyName: companyName,
          companyTaxId: companyTaxId,
          companyAddress: companyAddress,
          companyAlley: companyAlley,
          companyStreet: companyStreet,
          companyMoo: companyMoo,
          companyDistrict: companyDistrictId !== '' ? companyDistrictId : undefined,
          companyProvince: companyProvinceId !== '' ? companyProvinceId : undefined,
          companySubDistrict: companySubDistrictId !== '' ? companySubDistrictId : undefined,
          companyZipcode: companyZipcode,
          companyTel: companyTel,
        });
        bookingBankOld.forEach((item) => {
          bookingStore.deleteBookingBank(item.id);
        });
        bookingBank.forEach((item) => {
          bookingStore.createBookingBank({
            bank: item.bank !== "" ? item.bank : null,
            loanAmount: item.loanAmount !== "" ? item.loanAmount : null,
            interest: item.interest !== "" ? item.interest : null,
            status: item.status === "" ? null : item.status,
            updateBy: authStore.user?.id,
            updateAt: new Date(),
            customer: customerId,
            booking: id
          });
        });
        handleEditFileUpload(id);
        setIsLoading(false);
        enqueueSnackbar('บันทึกข้อมูลสำเร็จ', { variant: 'success' });
      }
    } catch (err) {
      console.error(err);
    }
  };
  const handleDelete = () => {
    try {
      setIsLoading(true);
      if (id) {
        bookingStore.update(id, {
          customer: {
            id: customerId,
            isDeleted: true,
            updateBy: authStore.user?.id,
            updateAt: new Date()
          },
          isDeleted: true,
          updateBy: authStore.user?.id,
          updateAt: new Date()
        });
        enqueueSnackbar('ลบข้อมูลสำเร็จ', { variant: 'success' });
        setIsLoading(false);
        navigate('/bookings');
      }
    } catch (err) {
      setIsLoading(false);
      console.error(err);
    }
  };

  const handleAddItem = () => {
    setBookingBank([...bookingBank, { id: uuid4(), bank: "", loanAmount: "", interest: "", statusBank: "all", isNew: true }]);
  };

  const handleRemoveItem = (id: string) => {
    setBookingBank(bookingBank.filter((item) => item.id !== id));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement> | any, name: string) => {
    let value: any | undefined;
    if (typeof e === 'object' && e?.target && 'value' in e?.target) {
      value = e?.target?.value;
    } else {
      value = e;
    }

    switch (name) {
      case 'companyName':
        setCompanyName(value || '');
        setData((prevData: any) => ({ ...prevData, companyName: value || '' }));
        break;
      case 'companyTaxId':
        setCompanyTaxId(value || '');
        setData((prevData: any) => ({ ...prevData, companyTaxId: value || '' }));
        break;
      case 'companyAddress':
        setCompanyAddress(value || '');
        setData((prevData: any) => ({ ...prevData, companyAddress: value || '' }));
        break;
      case 'companyAlley':
        setCompanyAlley(value || '');
        setData((prevData: any) => ({ ...prevData, companyAlley: value || '' }));
        break;
      case 'companyMoo':
        setCompanyMoo(value || '');
        setData((prevData: any) => ({ ...prevData, companyMoo: value || '' }));
        break;
      case 'companyStreet':
        setCompanyStreet(value || '');
        setData((prevData: any) => ({ ...prevData, companyStreet: value || '' }));
        break;
      case 'companyProvince':
        setCompanyProvince(value?.nameTh || '');
        setCompanyProvinceId(value?.id || '');
        setDistrictCompanyOptions(districtData.filter((district: any) => district?.province?.id === value?.id));
        setData((prevData: any) => ({ ...prevData, companyProvince: value || '' }));
        break;
      case 'companyDistrict':
        setCompanyDistrictId(value?.id);
        setCompanyDistrict(value?.nameTh);
        setSubDistrictCompanyOptions(subDistrictData.filter((subDistrict: any) => subDistrict?.amphur?.id === value?.id));
        setData((prevData: any) => ({ ...prevData, companyDistrict: value || '' }));
        break;
      case 'companySubDistrict':
        setCompanySubDistrictId(value?.id || '');
        setCompanySubDistrict(value?.nameTh || '');
        setCompanyZipcode(value?.zipCode || '');
        setData((prevData: any) => ({ ...prevData, companySubDistrict: value || '' }));
        break;
      case 'companyZipcode':
        setCompanyZipcode(value || '');
        setData((prevData: any) => ({ ...prevData, companyZipcode: value || '' }));
        break;
      case 'companyTel':
        setCompanyTel(value || '');
        setData((prevData: any) => ({ ...prevData, companyTel: value || '' }));
        break;
    }
  };

  const handleChangeCompanyType = (event: SelectChangeEvent) => {
    setCompanyTypes(event.target.value as string);
  };

  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      projectsStore.getAll(),
      housesStore.getAll(),
      masterDataStore.getAllProvince(),
      masterDataStore.getAllAmphur(),
      masterDataStore.getAllTambon(),
      statusStore.getAll(),
      masterDataStore.getBanksAll(),
      landStore.getByCriteria({
        page: 0,
        limit: 0
      }),
      masterDataStore.getReasonsTypeAll(),
      companyStore.getAll()
    ]).then((res) => {
      setProjectData(res[0]);
      setHouseData(res[1]);
      setProvinceData(res[2]);
      setProvinceData2(res[2]);
      setDistrictData(res[3]);
      setDistrictData2(res[3]);
      setSubDistrictData(res[4]);
      setSubDistrictData2(res[4]);
      setStatusData(res[5].filter((item: any) => item.statusType === "booking"));
      setStatusBankData(res[5].filter((item: any) => item.statusType === "lenderBank"));
      setBankData(res[6]);
      setReasonsTypeData(res[8].filter((item: any) => item.refType === "booking"));
      setCompanyInfo(res[9][0]);
      setIsLoading(false);
    }).catch((err) => {
      console.error(err);
      setIsLoading(false);
    });
    if (id) {
      bookingStore.get(id).then((res: any) => {
        setProject(res.project?.id);
        setHouse(res.house?.id);
        setFirstname(res?.customer?.firstname ?? "");
        setLastname(res?.customer?.lastname ?? "");
        setIdCard(res?.customer?.idCard ?? "");
        setAddress(res?.customer?.address ?? "");
        setAlley(res?.customer?.alley ?? "");
        setStreet(res?.customer?.street ?? "");
        setSubDistrict(res?.customer?.subDistrict ?? "");
        setSubDistrictValue(res?.customer?.subDistrict?.nameTh ?? "");
        setDistrict(res?.customer?.district ?? "");
        setDistrictValue(res?.customer?.district?.nameTh ?? "");
        setProvince(res?.customer?.province ?? "");
        setProvinceValue(res?.customer?.province?.nameTh ?? "");
        setZipCode(res?.customer?.zipCode ?? "");
        setTel(res?.customer?.tel ?? "");
        setSellingPrice(res?.sellingPrice ?? "");
        setBookingFee(res?.bookingFee ?? "");
        setContractFee(res?.contractFee ?? "");
        setArrearage(res?.arrearage ?? "");
        setRemark(res?.remark ?? "");
        setBookingNo(res?.bookingNo ?? "");
        setCustomerId(res?.customer?.id ?? "");
        setImages(res?.images ?? []);
        setData(res);
        setDocDate(dayjs(!res?.docDate ? new Date() : res?.docDate));
        setBirthdate(dayjs(!res?.customer?.birthdate ? new Date() : res?.customer?.birthdate));
        setGender(res?.customer?.gender ?? "");
        setMoo(res?.customer?.moo ?? "");
        setRemarkCancled(res?.remarkCancled ?? "");
        setSource(res?.source ?? "");
        setStatus(res?.status?.id ?? "");
        setOccupation(res?.customer?.occupation ?? "");
        setIncome(res?.customer?.income ?? 0);
        setOtherIncome(res?.customer?.otherIncome ?? 0);
        setSource(res?.customer?.source ?? "");
        setBookingBank(res?.bookingBank ?? []);
        setBookingBankOld(res?.bookingBank ?? []);
        setReasonsType(res?.reasonsType?.id ?? "");
        setFirstname2(res?.customer2?.firstname ?? "");
        setLastname2(res?.customer2?.lastname ?? "");
        setIdCard2(res?.customer2?.idCard ?? "");
        setAddress2(res?.customer2?.address ?? "");
        setAlley2(res?.customer2?.alley ?? "");
        setStreet2(res?.customer2?.street ?? "");
        setSubDistrictValue2(res?.customer2?.subDistrict?.nameTh ?? "");
        setDistrictValue2(res?.customer2?.district?.nameTh ?? "");
        setProvinceValue2(res?.customer2?.province?.nameTh ?? "");
        setZipCode2(res?.customer2?.zipCode ?? "");
        setTel2(res?.customer2?.tel ?? "");
        setBirthdate2(dayjs(!res?.customer2?.birthdate ? new Date() : res?.customer2?.birthdate));
        setGender2(res?.customer2?.gender ?? "");
        setMoo2(res?.customer2?.moo ?? "");
        setOccupation2(res?.customer2?.occupation ?? "");
        setIncome2(res?.customer2?.income ?? 0);
        setOtherIncome2(res?.customer2?.otherIncome ?? 0);
        setSource2(res?.customer2?.source ?? "");
        landStore.getByHouseId(res.house?.id).then((data: any) => {
          setLandData(data);
        });
        if (res.companyName) setCompanyTypes('person');
        setCompanyName(res.companyName);
        setCompanyTaxId(res.companyTaxId);
        setCompanyAddress(res.companyAddress);
        setCompanyAlley(res.companyAlley);
        setCompanyMoo(res.companyMoo);
        setCompanyStreet(res.companyStreet);
        setCompanyProvince(res.companyProvince?.nameTh);
        setCompanyProvinceId(res.companyProvince?.id);
        setCompanyDistrict(res.companyDistrict);
        setCompanyDistrictId(res.companyDistrict);
        setCompanySubDistrict(res.companySubDistrict);
        setCompanySubDistrictId(res.companySubDistrict);
        setCompanyZipcode(res.companyZipcode);
        setCompanyTel(res.companyTel);
        setIsLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box component={'div'}>
      <Grid container>
        <Grid item xs={0.5}>
          <IconButton onClick={() => navigate('/bookings')}>
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item xs={5.5} display={'flex'} alignItems={'center'}>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Typography>ขาย/จอง</Typography>
            <Link component="button" onClick={() => navigate('/bookings')}>จอง</Link>
            <Typography variant="h6" color="text.primary">{id ? 'แก้ไข' : 'สร้าง'}</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={3} display={'flex'} justifyContent={'end'}>
          {id &&
            <ReactToPrint
              trigger={() => {
                return (
                  <Button variant="outlined" color="success" startIcon={<PrintIcon />}>พิมพ์สัญญา</Button>
                );
              }}
              content={() => {
                return printRef.current;
              }}
            />
          }
        </Grid>
        <Grid item xs={3} display={'flex'} justifyContent={'end'}>
          {((permission && permission.delete) || authStore.user?.isSuperAdmin) && id &&
            <Button variant="contained" color="error" startIcon={<DeleteIcon />} onClick={() => setOpenConfirm({
              open: true,
              title: 'ลบเอกสาร',
              message: 'คุณต้องการลบเอกสารนี้ใช่หรือไม่',
              color: 'error',
              type: 'delete'
            })}>ลบเอกสาร</Button>
          }
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <TextField label="เลขที่จอง" variant="outlined" fullWidth value={bookingNo} disabled />
                </Grid>
                <Grid item xs={4} >
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'th'} >
                    <DatePicker
                      label="วันที่เอกสาร"
                      value={docDate}
                      onChange={(newValue) => setDocDate(newValue)}
                      sx={{ width: '100%' }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12}>
                  <Typography>ข้อมูลบริษัท</Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={companyTypes}
                      onChange={handleChangeCompanyType}
                    >
                      <FormControlLabel value="company" control={<Radio />} label="บริษัท" />
                      <FormControlLabel value="person" control={<Radio />} label="บุคคลธรรมดา" />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                {companyTypes === 'person' &&
                  <>
                    <Grid item xs={3}>
                      <TextField value={companyName} onChange={(e: any) => handleChange(e, 'companyName')} fullWidth label="ชื่อผู้ว่าจ้าง" inputRef={addRef}
                        onKeyDown={(e) => {
                          if (e.key === '-') {
                            e.preventDefault();
                          }
                          handleKeyDown(e, 33);
                        }} />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField value={companyTaxId} onChange={(e: any) => handleChange(e, 'companyTaxId')} fullWidth label="เลขประจําตัวผู้เสียภาษี"
                        inputRef={addRef}
                        onKeyDown={(e) => {
                          if (e.key === '-') {
                            e.preventDefault();
                          }
                          handleKeyDown(e, 34);
                        }} />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField value={companyAddress} onChange={(e: any) => handleChange(e, 'companyAddress')} fullWidth label="ที่อยู่"
                        inputRef={addRef}
                        onKeyDown={(e) => {
                          if (e.key === '-') {
                            e.preventDefault();
                          }
                          handleKeyDown(e, 35);
                        }} />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField value={companyMoo} onChange={(e: any) => handleChange(e, 'companyMoo')} fullWidth label="หมู่"
                        inputRef={addRef}
                        onKeyDown={(e) => {
                          if (e.key === '-') {
                            e.preventDefault();
                          }
                          handleKeyDown(e, 36);
                        }} />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField value={companyAlley} onChange={(e: any) => handleChange(e, 'companyAlley')} fullWidth label="ตรอก/ซอย" inputRef={addRef}
                        onKeyDown={(e) => {
                          if (e.key === '-') {
                            e.preventDefault();
                          }
                          handleKeyDown(e, 37);
                        }} />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField value={companyStreet} onChange={(e: any) => handleChange(e, 'companyStreet')} fullWidth label="ถนน" inputRef={addRef}
                        onKeyDown={(e) => {
                          if (e.key === '-') {
                            e.preventDefault();
                          }
                          handleKeyDown(e, 38);
                        }} />
                    </Grid>
                    <Grid item xs={3}>
                      <Autocomplete
                        value={companyProvince}
                        id='companyProvince'
                        onChange={(e: any, value: any) => handleChange(value, 'companyProvince')}
                        disablePortal
                        options={provinceData}
                        sx={{ width: '100%' }}
                        renderOption={(props: any, option: any) => <li {...props}>{option?.nameTh}</li>}
                        getOptionLabel={(option: any) => {
                          if (typeof option === 'string') {
                            return option;
                          }
                          if (option.inputValue) {
                            return option.inputValue;
                          }
                          return option.nameTh;
                        }}
                        renderInput={(params) => <TextField {...params} label='จังหวัด' inputRef={addRef}
                          onKeyDown={(e) => {
                            if (e.key === '-') {
                              e.preventDefault();
                            }
                            handleKeyDown(e, 7);
                          }} />}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Autocomplete
                        value={companyDistrict}
                        id='companyDistrict'
                        onChange={(e: any, value: any) => handleChange(value, 'companyDistrict')}
                        disablePortal
                        options={districtCompanyOptions}
                        sx={{ width: '100%' }}
                        renderOption={(props: any, option: any) => <li {...props}>{option?.nameTh}</li>}
                        getOptionLabel={(option: any) => {
                          if (typeof option === 'string') {
                            return option;
                          }
                          if (option.inputValue) {
                            return option.inputValue;
                          }
                          return option.nameTh;
                        }}
                        renderInput={(params) => <TextField {...params} label='อำเภอ' inputRef={addRef}
                          onKeyDown={(e) => {
                            if (e.key === '-') {
                              e.preventDefault();
                            }
                            handleKeyDown(e, 8);
                          }} />}
                        disabled={(districtCompanyOptions.length > 0 || companySubDistrict !== '') ? false : true}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Autocomplete
                        value={companySubDistrict ?? ''}
                        id='companySubDistrict'
                        onChange={(e: any, value: any) => handleChange(value, 'companySubDistrict')}
                        disablePortal
                        options={subDistrictCompanyOptions}
                        sx={{ width: '100%' }}
                        renderOption={(props: any, option: any) => <li {...props}>{option.nameTh}</li>}
                        getOptionLabel={(option: any) => {
                          if (typeof option === 'string') {
                            return option;
                          }
                          if (option.inputValue) {
                            return option.inputValue;
                          }
                          return option.nameTh;
                        }}
                        renderInput={(params) => <TextField {...params} label='ตำบล' inputRef={addRef}
                          onKeyDown={(e) => {
                            if (e.key === '-') {
                              e.preventDefault();
                            }
                            handleKeyDown(e, 9);
                          }} />}
                        disabled={(subDistrictCompanyOptions.length > 0 || companySubDistrict !== '') ? false : true}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField label='รหัสไปรษณีย์' variant='outlined' fullWidth value={companyZipcode} onChange={(e: any) =>
                        handleChange(e, 'companyZipcode')} inputRef={addRef}
                        onKeyDown={(e) => {
                          if (e.key === '-') {
                            e.preventDefault();
                          }
                          handleKeyDown(e, 10);
                        }} />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField label='เบอร์โทร' variant='outlined' fullWidth value={companyTel} onChange={(e: any) => handleChange(e, 'companyTel')} inputRef={addRef}
                        onKeyDown={(e) => {
                          if (e.key === '-') {
                            e.preventDefault();
                          }
                          handleKeyDown(e, 11);
                        }} />
                    </Grid>
                  </>
                }
                {companyTypes === 'company' &&
                  <>
                    <Grid item xs={4}>
                      <Typography>บริษัท {companyInfo?.name}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>ที่อยู่ {companyInfo?.address || '-'} หมู่ที่ {companyInfo?.moo || '-'} ตรอก/ซอย{companyInfo?.alley || '-'} ถนน{companyInfo?.street || '-'} ต.{companyInfo?.subDistrict?.nameTh || '-'} อ.{companyInfo?.district?.nameTh || '-'} จ.{companyInfo?.province?.nameTh || '-'} </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography>โทร {companyInfo?.tel || '-'}</Typography>
                    </Grid>
                  </>
                }
                <Grid item xs={12}>
                  <Typography>ข้อมูลลูกค้า</Typography>
                </Grid>
                <Grid item xs={3}>
                  <TextField label="ชื่อ" variant="outlined" fullWidth value={firstname} onChange={(e) => {
                    setFirstname(e.target.value);
                    setData({ ...data, customer: { ...data.customer, firstname: e.target.value } });
                  }}
                    inputRef={addRef}
                    onKeyDown={(e) => {
                      if (e.key === '-') {
                        e.preventDefault();
                      }
                      handleKeyDown(e, 0);
                    }} />
                </Grid>
                <Grid item xs={3}>
                  <TextField label="นามสกุล" variant="outlined" fullWidth value={lastname} onChange={(e) => {
                    setLastname(e.target.value);
                    setData({ ...data, customer: { ...data.customer, lastname: e.target.value } });
                  }} inputRef={addRef}
                    onKeyDown={(e) => {
                      if (e.key === '-') {
                        e.preventDefault();
                      }
                      handleKeyDown(e, 1);
                    }} />
                </Grid>
                <Grid item xs={3}>
                  <TextField label="เลขที่บัตรประชาชน" variant="outlined" fullWidth value={idCard} onChange={(e) => {
                    setIdCard(e.target.value);
                    setData({ ...data, customer: { ...data.customer, idCard: e.target.value } });
                  }} inputRef={addRef}
                    onKeyDown={(e) => {
                      if (e.key === '-') {
                        e.preventDefault();
                      }
                      handleKeyDown(e, 2);
                    }} />
                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth>
                    <InputLabel>เพศ</InputLabel>
                    <Select
                      value={gender}
                      label="เพศ"
                      onChange={(e) => { setGender(e.target.value); }}
                    >
                      <MenuItem value={'0'}>กรุณาเลือกเพศ</MenuItem>
                      <MenuItem value={'1'}>หญิง</MenuItem>
                      <MenuItem value={'2'}>ชาย</MenuItem>
                      <MenuItem value={'3'}>อื่นๆ</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={3} >
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'th'} >
                    <DatePicker
                      label="วันที่เกิด"
                      value={birthdate}
                      onChange={(newValue) => setBirthdate(newValue)}
                      sx={{ width: '100%' }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={3}>
                  <TextField label="ที่อยู่ เลขที่" variant="outlined" fullWidth value={address} onChange={(e) => {
                    setAddress(e.target.value);
                    setData({ ...data, customer: { ...data.customer, address: e.target.value } });
                  }} inputRef={addRef}
                    onKeyDown={(e) => {
                      if (e.key === '-') {
                        e.preventDefault();
                      }
                      handleKeyDown(e, 3);
                    }} />
                </Grid>
                <Grid item xs={3}>
                  <TextField label="หมู่" variant="outlined" fullWidth multiline value={moo} onChange={(e) => {
                    setMoo(e.target.value);
                    setData({ ...data, customer: { ...data.customer, moo: e.target.value } });
                  }} inputRef={addRef}
                    onKeyDown={(e) => {
                      if (e.key === '-') {
                        e.preventDefault();
                      }
                      handleKeyDown(e, 4);
                    }} />
                </Grid>
                <Grid item xs={3}>
                  <TextField label="ตรอก/ซอย" variant="outlined" fullWidth value={alley} onChange={(e) => {
                    setAlley(e.target.value);
                    setData({ ...data, customer: { ...data.customer, alley: e.target.value } });
                  }} inputRef={addRef}
                    onKeyDown={(e) => {
                      if (e.key === '-') {
                        e.preventDefault();
                      }
                      handleKeyDown(e, 5);
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField label="ถนน" variant="outlined" fullWidth value={street} onChange={(e) => {
                    setStreet(e.target.value);
                    setData({ ...data, customer: { ...data.customer, street: e.target.value } });
                  }} inputRef={addRef}
                    onKeyDown={(e) => {
                      if (e.key === '-') {
                        e.preventDefault();
                      }
                      handleKeyDown(e, 6);
                    }} />
                </Grid>
                <Grid item xs={3}>
                  <Autocomplete
                    value={provinceValue}
                    id="province"
                    onChange={(e: any, value: any) => {
                      if (value?.id) {
                        setProvince(value?.id);
                        setProvinceValue(value?.nameTh);
                        const _districtOptions = districtData.filter((district: any) => district?.province?.id === value?.id
                        );
                        setDistrictOptions(_districtOptions);
                        setData({ ...data, customer: { ...data.customer, province: value } });
                      }
                    }}
                    disablePortal
                    options={provinceData}
                    sx={{ width: '100%' }}
                    renderOption={(props: any, option: any) => <li {...props}>{option?.nameTh}</li>}
                    getOptionLabel={(option: any) => {
                      if (typeof option === 'string') {
                        return option;
                      }
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      return option.nameTh;
                    }}
                    renderInput={(params) => <TextField {...params} label="จังหวัด" inputRef={addRef}
                      onKeyDown={(e) => {
                        if (e.key === '-') {
                          e.preventDefault();
                        }
                        handleKeyDown(e, 7);
                      }} />}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Autocomplete
                    value={districtValue}
                    id="district"
                    onChange={(e: any, value: any) => {
                      setDistrict(value);
                      setDistrictValue(value?.nameTh);
                      setData({ ...data, customer: { ...data.customer, district: value } });
                      if (value?.id) {
                        const _subDistrictOptions = subDistrictData.filter((subDistrict: any) => subDistrict?.amphur?.id === value?.id);
                        setSubDistrictOptions(_subDistrictOptions);
                      }
                    }}
                    disablePortal
                    options={districtOptions}
                    sx={{ width: '100%' }}
                    renderOption={(props: any, option: any) => <li {...props}>{option?.nameTh}</li>}
                    getOptionLabel={(option: any) => {
                      if (typeof option === 'string') {
                        return option;
                      }
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      return option.nameTh;
                    }}
                    renderInput={(params) => <TextField {...params} label="อำเภอ" inputRef={addRef}
                      onKeyDown={(e) => {
                        if (e.key === '-') {
                          e.preventDefault();
                        }
                        handleKeyDown(e, 8);
                      }} />}
                    disabled={(districtOptions.length > 0 || districtValue !== "") ? false : true}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Autocomplete
                    value={subDistrictValue ?? ""}
                    id="subDistrict"
                    onChange={(e: any, value: any) => {
                      if (value?.id) {
                        setSubDistrict(value?.id);
                        setSubDistrictValue(value?.nameTh);
                        setData({ ...data, customer: { ...data.customer, subDistrict: value, zipCode: value?.zipCode ?? "" } });
                      }
                      if (value?.zipCode) {
                        setZipCode(value?.zipCode);
                      }
                    }}
                    disablePortal
                    options={subDistrictOptions}
                    sx={{ width: '100%' }}
                    renderOption={(props: any, option: any) => <li {...props}>{option.nameTh}</li>}
                    getOptionLabel={(option: any) => {
                      if (typeof option === 'string') {
                        return option;
                      }
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      return option.nameTh;
                    }}
                    renderInput={(params) => <TextField {...params} label="ตำบล" inputRef={addRef}
                      onKeyDown={(e) => {
                        if (e.key === '-') {
                          e.preventDefault();
                        }
                        handleKeyDown(e, 9);
                      }} />}
                    disabled={(subDistrictOptions.length > 0 || subDistrictValue !== "") ? false : true}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField label="รหัสไปรษณีย์" variant="outlined" fullWidth value={zipCode} onChange={(e) => {
                    setZipCode(e.target.value);
                    setData({ ...data, customer: { ...data.customer, zipCode: e.target.value } });
                  }} inputRef={addRef}
                    onKeyDown={(e) => {
                      if (e.key === '-') {
                        e.preventDefault();
                      }
                      handleKeyDown(e, 10);
                    }} />
                </Grid>
                <Grid item xs={3}>
                  <TextField label="เบอร์โทร" variant="outlined" fullWidth value={tel} onChange={(e) => {
                    setTel(e.target.value);
                    setData({ ...data, customer: { ...data.customer, tel: e.target.value } });
                  }} inputRef={addRef}
                    onKeyDown={(e) => {
                      if (e.key === '-') {
                        e.preventDefault();
                      }
                      handleKeyDown(e, 11);
                    }} />
                </Grid>
                <Grid item xs={3}>
                  <TextField label="อาชีพ" variant="outlined" fullWidth value={occupation} onChange={(e) => setOccupation(e.target.value)} inputRef={addRef}
                    onKeyDown={(e) => {
                      if (e.key === '-') {
                        e.preventDefault();
                      }
                      handleKeyDown(e, 12);
                    }} />
                </Grid>
                <Grid item xs={3}>
                  <TextField label="รายได้" variant="outlined" fullWidth value={income ? parseFloat(income) : ''} onChange={(e) => setIncome(e.target.value)} inputRef={addRef}
                    type="number" onKeyDown={(e) => {
                      if (e.key === '-') {
                        e.preventDefault();
                      }
                      handleKeyDown(e, 13);
                    }} />
                </Grid>
                <Grid item xs={3}>
                  <TextField label="รายได้อื่นๆ" variant="outlined" fullWidth value={otherIncome ? parseFloat(otherIncome) : ''} onChange={(e) => setOtherIncome(e.target.value)} type="number" inputRef={addRef}
                    onKeyDown={(e) => {
                      if (e.key === '-') {
                        e.preventDefault();
                      }
                      handleKeyDown(e, 14);
                    }} />
                </Grid>
                <Grid item xs={9}>
                  <TextField label="แหล่งที่มา" variant="outlined" fullWidth multiline value={source} onChange={(e) => setSource(e.target.value)} inputRef={addRef}
                    onKeyDown={(e) => {
                      if (e.key === '-') {
                        e.preventDefault();
                      }
                      handleKeyDown(e, 15);
                    }} />
                </Grid>
                <Grid item xs={12}>
                  <Typography>ข้อมูลลูกค้าเพิ่มเติม(ถ้ามี)</Typography>
                </Grid>
                <Grid item xs={3}>
                  <TextField label="ชื่อ" variant="outlined" fullWidth value={firstname2} onChange={(e) => {
                    setFirstname2(e.target.value);
                    setData({ ...data, customer2: { ...data.customer2, firstname: e.target.value } });
                  }}
                    inputRef={addRef}
                    onKeyDown={(e) => {
                      if (e.key === '-') {
                        e.preventDefault();
                      }
                      handleKeyDown(e, 0);
                    }} />
                </Grid>
                <Grid item xs={3}>
                  <TextField label="นามสกุล" variant="outlined" fullWidth value={lastname2} onChange={(e) => {
                    setLastname2(e.target.value);
                    setData({ ...data, customer2: { ...data.customer2, lastname: e.target.value } });
                  }} inputRef={addRef}
                    onKeyDown={(e) => {
                      if (e.key === '-') {
                        e.preventDefault();
                      }
                      handleKeyDown(e, 1);
                    }} />
                </Grid>
                <Grid item xs={3}>
                  <TextField label="เลขที่บัตรประชาชน" variant="outlined" fullWidth value={idCard2} onChange={(e) => {
                    setIdCard2(e.target.value);
                    setData({ ...data, customer2: { ...data.customer2, idCard: e.target.value } });
                  }} inputRef={addRef}
                    onKeyDown={(e) => {
                      if (e.key === '-') {
                        e.preventDefault();
                      }
                      handleKeyDown(e, 2);
                    }} />
                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth>
                    <InputLabel>เพศ</InputLabel>
                    <Select
                      value={gender2}
                      label="เพศ"
                      onChange={(e) => {
                        setData({ ...data, customer2: { ...data.customer2, gender: e.target.value } });
                        setGender2(e.target.value);
                      }}
                    >
                      <MenuItem value={'0'}>กรุณาเลือกเพศ</MenuItem>
                      <MenuItem value={'1'}>หญิง</MenuItem>
                      <MenuItem value={'2'}>ชาย</MenuItem>
                      <MenuItem value={'3'}>อื่นๆ</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={3} >
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'th'} >
                    <DatePicker
                      label="วันที่เกิด"
                      value={birthdate2}
                      onChange={(newValue) => setBirthdate2(newValue)}
                      sx={{ width: '100%' }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={3}>
                  <TextField label="ที่อยู่ เลขที่" variant="outlined" fullWidth value={address2} onChange={(e) => {
                    setAddress2(e.target.value);
                    setData({ ...data, customer2: { ...data.customer2, address: e.target.value } });
                  }} inputRef={addRef}
                    onKeyDown={(e) => {
                      if (e.key === '-') {
                        e.preventDefault();
                      }
                      handleKeyDown(e, 3);
                    }} />
                </Grid>
                <Grid item xs={3}>
                  <TextField label="หมู่" variant="outlined" fullWidth multiline value={moo2} onChange={(e) => {
                    setMoo2(e.target.value);
                    setData({ ...data, customer2: { ...data.customer2, moo: e.target.value } });
                  }} inputRef={addRef}
                    onKeyDown={(e) => {
                      if (e.key === '-') {
                        e.preventDefault();
                      }
                      handleKeyDown(e, 4);
                    }} />
                </Grid>
                <Grid item xs={3}>
                  <TextField label="ตรอก/ซอย" variant="outlined" fullWidth value={alley2} onChange={(e) => {
                    setAlley2(e.target.value);
                    setData({ ...data, customer2: { ...data.customer2, alley: e.target.value } });
                  }} inputRef={addRef}
                    onKeyDown={(e) => {
                      if (e.key === '-') {
                        e.preventDefault();
                      }
                      handleKeyDown(e, 5);
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField label="ถนน" variant="outlined" fullWidth value={street2} onChange={(e) => {
                    setStreet2(e.target.value);
                    setData({ ...data, customer2: { ...data.customer2, street: e.target.value } });
                  }} inputRef={addRef}
                    onKeyDown={(e) => {
                      if (e.key === '-') {
                        e.preventDefault();
                      }
                      handleKeyDown(e, 6);
                    }} />
                </Grid>
                <Grid item xs={3}>
                  <Autocomplete
                    value={provinceValue2}
                    id="province2"
                    onChange={(e: any, value: any) => {
                      if (value?.id) {
                        setProvinceValue2(value?.nameTh);
                        const _districtOptions = districtData2.filter((district: any) => district?.province?.id === value?.id
                        );
                        setDistrictOptions2(_districtOptions);
                        setData({ ...data, customer2: { ...data.customer2, province: value } });
                      }
                    }}
                    disablePortal
                    options={provinceData2}
                    sx={{ width: '100%' }}
                    renderOption={(props: any, option: any) => <li {...props}>{option?.nameTh}</li>}
                    getOptionLabel={(option: any) => {
                      if (typeof option === 'string') {
                        return option;
                      }
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      return option.nameTh;
                    }}
                    renderInput={(params) => <TextField {...params} label="จังหวัด" inputRef={addRef}
                      onKeyDown={(e) => {
                        if (e.key === '-') {
                          e.preventDefault();
                        }
                        handleKeyDown(e, 7);
                      }} />}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Autocomplete
                    value={districtValue2}
                    id="district2"
                    onChange={(e: any, value: any) => {
                      setDistrictValue2(value?.nameTh);
                      setData({ ...data, customer2: { ...data.customer2, district: value } });
                      if (value?.id) {
                        const _subDistrictOptions = subDistrictData2.filter((subDistrict: any) => subDistrict?.amphur?.id === value?.id);
                        setSubDistrictOptions2(_subDistrictOptions);
                      }
                    }}
                    disablePortal
                    options={districtOptions2}
                    sx={{ width: '100%' }}
                    renderOption={(props: any, option: any) => <li {...props}>{option?.nameTh}</li>}
                    getOptionLabel={(option: any) => {
                      if (typeof option === 'string') {
                        return option;
                      }
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      return option.nameTh;
                    }}
                    renderInput={(params) => <TextField {...params} label="อำเภอ" inputRef={addRef}
                      onKeyDown={(e) => {
                        if (e.key === '-') {
                          e.preventDefault();
                        }
                        handleKeyDown(e, 8);
                      }} />}
                    disabled={(districtOptions2.length > 0 || districtValue2 !== "") ? false : true}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Autocomplete
                    value={subDistrictValue2 ?? ""}
                    id="subDistrict"
                    onChange={(e: any, value: any) => {
                      if (value?.id) {
                        setSubDistrictValue2(value?.nameTh);
                        setData({ ...data, customer2: { ...data.customer2, subDistrict: value, zipCode: value?.zipCode ?? "" } });
                      }
                      if (value?.zipCode) {
                        setZipCode2(value?.zipCode);
                      }
                    }}
                    disablePortal
                    options={subDistrictOptions2}
                    sx={{ width: '100%' }}
                    renderOption={(props: any, option: any) => <li {...props}>{option.nameTh}</li>}
                    getOptionLabel={(option: any) => {
                      if (typeof option === 'string') {
                        return option;
                      }
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      return option.nameTh;
                    }}
                    renderInput={(params) => <TextField {...params} label="ตำบล" inputRef={addRef}
                      onKeyDown={(e) => {
                        if (e.key === '-') {
                          e.preventDefault();
                        }
                        handleKeyDown(e, 9);
                      }} />}
                    disabled={(subDistrictOptions2.length > 0 || subDistrictValue2 !== "") ? false : true}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField label="รหัสไปรษณีย์" variant="outlined" fullWidth value={zipCode2} onChange={(e) => {
                    setZipCode2(e.target.value);
                    setData({ ...data, customer2: { ...data.customer2, zipCode: e.target.value } });
                  }} inputRef={addRef}
                    onKeyDown={(e) => {
                      if (e.key === '-') {
                        e.preventDefault();
                      }
                      handleKeyDown(e, 10);
                    }} />
                </Grid>
                <Grid item xs={3}>
                  <TextField label="เบอร์โทร" variant="outlined" fullWidth value={tel2} onChange={(e) => {
                    setTel2(e.target.value);
                    setData({ ...data, customer2: { ...data.customer2, tel: e.target.value } });
                  }} inputRef={addRef}
                    onKeyDown={(e) => {
                      if (e.key === '-') {
                        e.preventDefault();
                      }
                      handleKeyDown(e, 11);
                    }} />
                </Grid>
                <Grid item xs={3}>
                  <TextField label="อาชีพ" variant="outlined" fullWidth value={occupation2} onChange={(e) => {
                    setOccupation2(e.target.value);
                    setData({ ...data, customer2: { ...data.customer2, occupation: e.target.value } });
                  }} inputRef={addRef}
                    onKeyDown={(e) => {
                      if (e.key === '-') {
                        e.preventDefault();
                      }
                      handleKeyDown(e, 12);
                    }} />
                </Grid>
                <Grid item xs={3}>
                  <TextField label="รายได้" variant="outlined" fullWidth value={income2 ? parseFloat(income2) : ''} onChange={(e) => {
                    setIncome2(e.target.value);
                  }} inputRef={addRef}
                    type="number" onKeyDown={(e) => {
                      if (e.key === '-') {
                        e.preventDefault();
                      }
                      handleKeyDown(e, 13);
                    }} />
                </Grid>
                <Grid item xs={3}>
                  <TextField label="รายได้อื่นๆ" variant="outlined" fullWidth value={otherIncome2 ? parseFloat(otherIncome2) : ''} onChange={(e) => {
                    setOtherIncome2(e.target.value);
                  }} type="number" inputRef={addRef}
                    onKeyDown={(e) => {
                      if (e.key === '-') {
                        e.preventDefault();
                      }
                      handleKeyDown(e, 14);
                    }} />
                </Grid>
                <Grid item xs={9}>
                  <TextField label="แหล่งที่มา" variant="outlined" fullWidth multiline value={source2} onChange={(e) => {
                    setSource2(e.target.value);
                    setData({ ...data, customer2: { ...data.customer2, source: e.target.value } });
                  }} inputRef={addRef}
                    onKeyDown={(e) => {
                      if (e.key === '-') {
                        e.preventDefault();
                      }
                      handleKeyDown(e, 15);
                    }} />
                </Grid>
                <Grid item xs={12}>
                  <Typography>ข้อมูลบ้าน</Typography>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel>โครงการ</InputLabel>
                    <Select
                      value={project}
                      label="โครงการ"
                      onChange={handleChangeProject}
                      inputRef={addRef}
                      onKeyDown={(e) => {
                        if (e.key === '-') {
                          e.preventDefault();
                        }
                        handleKeyDown(e, 16);
                      }}
                    >
                      <MenuItem value={'all'}>กรุณาเลือกโครงการ</MenuItem>
                      {projectData && projectData.length > 0 && projectData?.map((item: any) => (
                        <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel>บ้าน</InputLabel>
                    <Select
                      disabled={project === 'all' ? true : false}
                      value={house}
                      label="บ้าน"
                      onChange={handleChangeHouse}
                      inputRef={addRef}
                      onKeyDown={(e) => {
                        if (e.key === '-') {
                          e.preventDefault();
                        }
                        handleKeyDown(e, 17);
                      }}
                    >
                      {houseDataFilter?.length > 0 && houseDataFilter?.map((item: any) => (
                        <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>))}
                      {houseDataFilter?.length === 0 && houseData?.map((item: any) => (
                        <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>))}

                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  {/* ส่วนแสดงรายละเอียดที่ดิน */}
                  <Typography>ข้อมูลที่ดิน</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography><b>เลขที่โฉนด: </b>{landData?.deedNo}</Typography>
                  <Typography><b>เนื้อที่: </b>{landData?.rai ? parseFloat(landData?.rai ?? 0).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : "-"} ไร่ {landData?.ngan ? parseFloat(landData?.ngan).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : "-"} งาน {landData?.squareWah ? parseFloat(landData?.squareWah ?? 0).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : "-"} ตร.วา</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>ข้อมูลซื้อขาย</Typography>
                </Grid>
                <Grid item xs={2}>
                  <TextField label="ราคาขาย" variant="outlined" fullWidth value={sellingPrice ? parseFloat(sellingPrice.toString()) : ''} onChange={(e: any) => {
                    setSellingPrice(e.target.value);
                    setData({ ...data, sellingPrice: e.target.value });
                  }}
                    type="number"
                    inputRef={addRef}
                    onKeyDown={(e) => {
                      if (e.key === '-') {
                        e.preventDefault();
                      }
                      handleKeyDown(e, 18);
                    }} />
                </Grid>
                <Grid item xs={2}>
                  <TextField label="ค่าจอง" variant="outlined" fullWidth value={bookingFee ? parseFloat(bookingFee.toString()) : ''} onChange={(e: any) => {
                    setBookingFee(e.target.value);
                    setData({ ...data, bookingFee: e.target.value });
                  }} type="number" inputRef={addRef}
                    onKeyDown={(e) => {
                      if (e.key === '-') {
                        e.preventDefault();
                      }
                      handleKeyDown(e, 19);
                    }} />
                </Grid>
                <Grid item xs={2}>
                  <TextField label="ค่าทำสัญญา" variant="outlined" fullWidth value={contractFee ? parseFloat(contractFee.toString()) : ''} onChange={(e: any) => {
                    setContractFee(e.target.value);
                    setData({ ...data, contractFee: e.target.value });
                  }} type="number" inputRef={addRef}
                    onKeyDown={(e) => {
                      if (e.key === '-') {
                        e.preventDefault();
                      }
                      handleKeyDown(e, 20);
                    }} />
                </Grid>
                <Grid item xs={2}>
                  <TextField label="ที่ต้องชำระในวันโอน" variant="outlined" fullWidth value={arrearage ? parseFloat(arrearage.toString()) : ''} onChange={(e: any) => {
                    setArrearage(e.target.value);
                    setData({ ...data, arrearage: e.target.value });
                  }} type="number" inputRef={addRef}
                    onKeyDown={(e) => {
                      if (e.key === '-') {
                        e.preventDefault();
                      }
                      handleKeyDown(e, 21);
                    }} />
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel>สถานะ</InputLabel>
                    <Select
                      value={status}
                      label="สถานะ"
                      onChange={(e) => setStatus(e.target.value !== 'all' ? e.target.value : '')}
                      inputRef={addRef}
                      onKeyDown={(e) => {
                        if (e.key === '-') {
                          e.preventDefault();
                        }
                        handleKeyDown(e, 22);
                      }}
                    >
                      <MenuItem value={'all'}>ทั้งหมด</MenuItem>
                      {statusData.map((item: any) => (
                        <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField label="หมายเหตุ" variant="outlined" fullWidth multiline rows={4} value={remark} onChange={(e) => {
                    setRemark(e.target.value);
                    setData({ ...data, remark: e.target.value });
                  }} />
                </Grid>
                {
                  statusData.filter((item: any) => item.name === "ยกเลิกการจอง").map((item: any) => item.id).includes(status) &&
                  <>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel>ประเภทการยกเลิก</InputLabel>
                        <Select
                          value={reasonsType}
                          label="บ้าน"
                          onChange={
                            handleChangeReasonsType
                          }
                        >
                          {reasonsTypeData?.length > 0 && reasonsTypeData?.map((item: any) => (
                            <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField label="หมายเหตุการยกเลิกใบจอง" variant="outlined" fullWidth multiline rows={4} value={remarkCancled} onChange={(e) => setRemarkCancled(e.target.value)} />
                    </Grid>
                  </>
                }
                <Grid item xs={12} display={'flex'} alignItems={'center'}>
                  <Typography>ข้อมูลธนาคาร</Typography>
                  <IconButton onClick={handleAddItem}>
                    <AddCircleIcon color="primary" />
                  </IconButton>
                </Grid>

                {bookingBank.length > 0 && bookingBank.map((item: any, index: number) => (
                  <>
                    <Grid item xs={0.5} mb={1.5} justifyContent={'center'} alignItems={'center'} display={'flex'}>
                      <IconButton aria-label={`delete-${item.id}`} size="small" color="error" onClick={() => handleRemoveItem(bookingBank[index].id)}>
                        <RemoveCircleIcon />
                      </IconButton>
                    </Grid>
                    <Grid item xs={3}>
                      <FormControl fullWidth>
                        <Autocomplete
                          id="bank-select"
                          options={bankData} //ข้อมูลธนาคารทั้งหมด
                          getOptionLabel={(option) => option.nameTh} //เลือกวิธีดึงข้อมูลโดยดึงข้อมูลของ
                          value={item?.bank !== "" ? item?.bank : null} //หาค่าที่ให้ตรงกับ id
                          onChange={(event, newValue) => {
                            const newItems = [...bookingBank];
                            newItems[index].bank = newValue;
                            setBookingBank(newItems);
                          }}
                          renderInput={(params) => (
                            <TextField {...params} label="ธนาคาร" />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={2.5} >
                      <TextField label="อัตราดอกเบี้ย" variant="outlined" fullWidth value={item?.interest ? parseFloat(item?.interest.toString()) : ''} type="number" onChange={(e) => {
                        const newItems = [...bookingBank];
                        newItems[index].interest = e.target.value;
                        setBookingBank(newItems);
                      }} />
                    </Grid>
                    <Grid item xs={3} >
                      <TextField label="ยอดกู้" variant="outlined" fullWidth value={item?.loanAmount ? parseFloat(item?.loanAmount.toString()) : ''} type="number" onChange={(e) => {
                        const newItems = [...bookingBank];
                        newItems[index].loanAmount = e.target.value;
                        setBookingBank(newItems);
                      }} />
                    </Grid>
                    <Grid item xs={3} >
                      <FormControl fullWidth>
                        <InputLabel>สถานะการกู้</InputLabel>
                        <Select
                          value={item?.status?.id ? item?.status?.id : item?.status}
                          label="สถานะการกู้"
                          onChange={(e) => {
                            const newItems = [...bookingBank];
                            newItems[index].status = e.target.value;
                            setBookingBank(newItems);
                          }}
                        >
                          <MenuItem value={'all'}>ทั้งหมด</MenuItem>
                          {statusBankData.map((item: any) => (
                            <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </>
                ))}
                <Grid item xs={12}><Divider /></Grid>
                <Grid item xs={8}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography>เอกสาร</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Button component="label" variant="outlined" startIcon={<UploadFileIcon />}>
                        เลือกไฟล์
                        <VisuallyHiddenInput type="file" accept=".jpg, .jpeg, .png"
                          onChange={handleFileChange} />
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="caption">รองรับไฟล์นามสกุล .jpg, .jpeg, .png</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      {selectedFiles.length > 0 && (
                        <Box component={'div'} mt={1}>
                          {selectedFiles.map((file: any, index: number) => (
                            <Grid item xs={12} key={`sf-${index.toString()}`} mt={1}>
                              <Grid container >
                                <Grid item xs={0.5} justifyContent={'center'} alignItems={'center'} display={'flex'}>
                                  <IconButton size="small" color="error"
                                    onClick={() => handleFileRemove(index)}>
                                    <RemoveCircleIcon />
                                  </IconButton>
                                </Grid>
                                <Grid item xs={11.5}>
                                  <img
                                    src={URL.createObjectURL(file)}
                                    alt={`ตัวอย่างไฟล์ ${index + 1}`}
                                    width="500"
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          ))}
                        </Box>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      {images && (
                        <Box component={'div'} mt={1}>
                          {images.map((file: any, index: number) => (
                            <Grid item xs={12} key={`im-${index.toString()}`} mt={1}>
                              <Grid container >
                                <Grid item xs={0.5} justifyContent={'center'} alignItems={'center'} display={'flex'}>
                                  <IconButton size="small" color="error"
                                    onClick={() => handleFileRemoveImageList(file)}>
                                    <RemoveCircleIcon />
                                  </IconButton>
                                </Grid>
                                <Grid item xs={11.5}>
                                  <img
                                    src={`${apiUrl}/uploads/${file.name}`}
                                    alt={`ตัวอย่างไฟล์ ${index + 1}`}
                                    width="500"
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          ))}
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} >
                      <Stack spacing={2} direction="row">
                        <Button variant="outlined" fullWidth onClick={() => navigate('/bookings')}>ยกเลิก</Button>
                        {!id && <Button variant="contained" fullWidth onClick={() => {
                          setOpenConfirm({
                            open: true,
                            title: 'บันทึกเอกสาร',
                            message: 'คุณต้องการบันทึกเอกสารนี้ใช่หรือไม่',
                            color: 'primary',
                            type: 'create'
                          });
                        }}>บันทึก</Button>}
                        {((permission && permission.edit) || authStore.user?.isSuperAdmin) && id && <Button variant="contained" fullWidth onClick={() => {
                          setOpenConfirm({
                            open: true,
                            title: 'แก้ไขเอกสาร',
                            message: 'คุณต้องการแก้ไขเอกสารนี้ใช่หรือไม่',
                            color: 'primary',
                            type: 'edit'
                          });
                        }}>แก้ไข</Button>}
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <SimpleBackdrop open={isLoading} />
      <DialogConfirm open={openConfirm.open} type={openConfirm.color} title={openConfirm.title} message={openConfirm.message} onClose={() => setOpenConfirm({ ...openConfirm, open: false })}
        onSubmit={() => {
          setOpenConfirm({ ...openConfirm, open: false });
          if (openConfirm.type === 'delete') {
            handleDelete();
          } else if (openConfirm.type === 'create') {
            handleSave();
          } else if (openConfirm.type === 'edit') {
            handleEdit();
          }
        }} />
      <Box sx={{ display: "none" }}>
        <PrintContract ref={printRef} title={'สัญญาจอง'} data={data} paidTotal={(parseFloat(data.bookingFee || "0") + parseFloat(data.contractFee || "0")).toString()} companyInfo={companyInfo} companyTypes={companyTypes} />
      </Box>
    </Box>
  );
};

export default BookingCreate;