import { Box, Breadcrumbs, Button, Card, CardContent, FormControl, FormControlLabel, Grid, IconButton, InputLabel, MenuItem, Paper, Radio, RadioGroup, Select, SelectChangeEvent, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import React, { ChangeEvent, RefObject, createRef, useEffect, useRef, useState } from 'react';
import SimpleBackdrop from "../../components/SimpleBackdrop";
import projectsStore from "../../stores/ProjectsStore";
import housesStore from "../../stores/HousesStore";
import { useNavigate } from "react-router-dom";
import authStore from "../../stores/AuthStore";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/th';
import SearchIcon from '@mui/icons-material/Search';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import DownloadIcon from '@mui/icons-material/Download';
import PrintIcon from '@mui/icons-material/Print';
import { DownloadTableExcel } from "react-export-table-to-excel";
import ReactToPrint from "react-to-print";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import bookingStore from "../../stores/BookingStore";
import statusStore from "../../stores/StatusStore";
import PrintBooking from "../../components/Report/Pdf/PrintBooking";
import ExportFileBookings from "../../components/Report/Excel/ExportFileBooking";
import ChipCustom from "../../components/ChipCustom";
import PrintContract from "../../components/Report/Pdf/PrintContract";
import companyStore from "../../stores/CompanyStore";

const Bookings: React.FC = () => {
  const navigate = useNavigate();
  const permission: any = authStore.user?.roles.find((role: any) => role.menu.path === '/bookings');
  const [isLoading, setIsLoading] = useState(false);
  const [project, setProject] = useState('all');
  const [house, setHouse] = useState('all');
  const [projectData, setProjectData] = useState([]);
  const [houseData, setHouseData] = useState([]);
  const [dateFrom, setDateFrom] = useState<Dayjs | null>(dayjs().startOf('month'));
  const [dateTo, setDateTo] = useState<Dayjs | null>(dayjs());
  const [docStatus, setDocStatus] = useState('all');
  const [total, setTotal] = useState(0);
  const printRef = useRef(null);
  const exportRef = useRef(null);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [rows, setRows] = useState<any>([]);
  const [name, setName] = useState('');
  const [bookingNo, setBookingNo] = useState('');
  const [tel, setTel] = useState('');
  const [statusData, setStatusData] = useState<any[]>([]);
  const [dateTypes, setDateType] = useState('createDate');
  const [sortType, setSortType] = useState({ name: "วันที่บันทึก: ล่าสุด", variable: "createAtDESC", sortBy: "createAt", sortType: "DESC" });
  const sortOption = [
    { name: "วันที่บันทึก: ล่าสุด", variable: "createAtDESC", sortBy: "createAt", sortType: "DESC" },
    { name: "วันที่บันทึก: เก่าสุด", variable: "createAtASC", sortBy: "createAt", sortType: "ASC" },
    { name: "วันที่จอง: ล่าสุด", variable: "docDateDESC", sortBy: "docDate", sortType: "DESC" },
    { name: "วันที่จอง: เก่าสุด", variable: "docDateASC", sortBy: "docDate", sortType: "ASC" },
    { name: "วันที่แก้ไข: ล่าสุด", variable: "updateAtDESC", sortBy: "updateAt", sortType: "DESC" },
    { name: "วันที่แก้ไข: เก่าสุด", variable: "updateAtASC", sortBy: "updateAt", sortType: "ASC" },
  ];
  const [sortValue, setSortValue] = useState("createAtDESC");
  const printOneRef = useRef<RefObject<HTMLTableRowElement>[]>([]);
  const [companyInfo, setCompanyInfo] = useState<any>([]);

  const handleChangeProject = (event: SelectChangeEvent) => {
    setProject(event.target.value as string);
  };

  const handleChangeHouse = (event: SelectChangeEvent) => {
    setHouse(event.target.value as string);
  };

  const handleChangeDocStatus = (event: SelectChangeEvent) => {
    setDocStatus(event.target.value as string);
  };

  const handleSearch = (pageNumber?: string, limitNumber?: string, dateF?: Dayjs, dateT?: Dayjs, _sortType?: string, isRefresh = false) => {
    setIsLoading(true);
    setRows([]);
    const _page = parseInt(pageNumber || '0');
    const _limit = parseInt(limitNumber || '0');
    const data = {
      dateFrom: dateF ? dateF.format('YYYY-MM-DD') : dateFrom?.format('YYYY-MM-DD'),
      dateTo: dateT ? dateT.format('YYYY-MM-DD') : dateTo?.format('YYYY-MM-DD'),
      project: project,
      house: house,
      status: docStatus,
      name: name,
      tel: tel,
      bookingNo: bookingNo,
      limit: _limit,
      page: _page,
      dateTypes: dateTypes,
      sortType: _sortType ? _sortType : sortType,
    };
    bookingStore.getByCriteria(data).then((res) => {
      const data = res.data;
      if ((res?.total && res?.total <= 10) || isRefresh === true) {
        setPage(0);
        setLimit(10);
      };
      setRows(data);
      setTotal(res.total);
      const _sortType = localStorage.getItem('sortType');
      const _sortTypeConvert: any = _sortType ? JSON.parse(_sortType) : sortType;
      setSortType(_sortTypeConvert || sortType);
      setSortValue(_sortTypeConvert?.variable || 'createAtDESC');
      if (!_sortType) {
        localStorage.setItem('sortType', JSON.stringify(sortType));
      }
      setIsLoading(false);
    });
  };
  const handleChangeDateType = (event: ChangeEvent<HTMLInputElement>) => {
    setDateType((event.target as HTMLInputElement).value);
  };

  window.addEventListener("beforeunload", function (event) {
    localStorage.removeItem('dateFrom');
    localStorage.removeItem('dateTo');
    localStorage.removeItem('sortType');
    localStorage.removeItem('page');
    localStorage.removeItem('limit');
  });

  const handleChangeSort = (e: any) => {
    const findSortType: any = sortOption.find((item: any) => item.variable === e.target.value);
    localStorage.setItem('sortType', JSON.stringify(findSortType));
    setSortType(findSortType);
    setSortValue(e.target.value);
  };

  useEffect(() => {
    setIsLoading(true);
    const _dateFrom = localStorage.getItem('dateFrom');
    const _dateTo = localStorage.getItem('dateTo');
    const dateF = dayjs(_dateFrom ? _dateFrom : dateFrom);
    const dateT = dayjs(_dateTo ? _dateTo : dateTo);
    const _limit = localStorage.getItem('limit');
    const _page = localStorage.getItem('page');
    setDateFrom(dateF);
    setDateTo(dateT);
    setLimit(_limit ? parseInt(_limit) : limit);
    setPage(_page ? parseInt(_page) : page);
    const _sortType: any = localStorage.getItem('sortType');
    const _sortTypeConvert: any = _sortType ? JSON.parse(_sortType) : sortType;
    setSortType(_sortTypeConvert || sortType);
    setSortValue(_sortTypeConvert?.variable || 'createAtDESC');
    if (!_sortType) {
      localStorage.setItem('sortType', JSON.stringify(sortType));
    }
    if (!_limit) {
      localStorage.setItem('limit', '10');
    }
    if (!_page) {
      localStorage.setItem('page', '0');
    }
    handleSearch(_page || undefined, _limit || undefined, dateF || undefined, dateT || undefined, _sortTypeConvert);
    Promise.all([
      projectsStore.getAll(),
      housesStore.getAll(),
      statusStore.getAll(),
      companyStore.getAll()
    ]).then((res) => {
      setProjectData(res[0]);
      setHouseData(res[1]);
      setStatusData(res[2].filter((item: any) => item.statusType === "booking"));
      setCompanyInfo(res[3][0]);
      setIsLoading(false);
    }).catch((err) => {
      console.error(err);
      setIsLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box component={'div'}>
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Typography>ขาย/จอง</Typography>
        <Typography variant="h6" color="text.primary">จอง</Typography>
      </Breadcrumbs>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2} alignItems={'end'}>
                <Grid item xs={12}>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={dateTypes}
                      onChange={handleChangeDateType}
                    >
                      <FormControlLabel value="createDate" control={<Radio />} label="วันที่บันทึก" />
                      <FormControlLabel value="docDate" control={<Radio />} label="วันที่เอกสาร" />
                    </RadioGroup>
                  </FormControl>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'th'}>
                    <DemoContainer components={['DatePicker', 'DatePicker']}>
                      <DatePicker
                        label="ตั้งแต่วันที่"
                        value={dateFrom}
                        onChange={(newValue) => {
                          setDateFrom(newValue);
                          localStorage.setItem('dateFrom', newValue?.format('YYYY-MM-DD') || '');
                        }} />
                      <DatePicker
                        label="ถึงวันที่"
                        value={dateTo}
                        onChange={(newValue) => {
                          setDateTo(newValue);
                          localStorage.setItem('dateTo', newValue?.format('YYYY-MM-DD') || '');
                        }} />
                    </DemoContainer>
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={5}>
                  <FormControl fullWidth>
                    <InputLabel>โครงการ</InputLabel>
                    <Select
                      value={project}
                      label="โครงการ"
                      onChange={handleChangeProject}
                    >
                      <MenuItem value={'all'}>ทั้งหมด</MenuItem>
                      {projectData && projectData?.length > 0 && projectData?.map((item: any) => (
                        <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={5}>
                  <FormControl fullWidth>
                    <InputLabel>บ้าน</InputLabel>
                    <Select
                      value={house}
                      label="บ้าน"
                      onChange={handleChangeHouse}
                    >
                      <MenuItem value={'all'}>ทั้งหมด</MenuItem>
                      {houseData && houseData?.length > 0 && houseData?.map((item: any) => (
                        <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <FormControl fullWidth >
                    <InputLabel>สถานะเอกสาร</InputLabel>
                    <Select
                      value={docStatus}
                      label="สถานะเอกสาร"
                      onChange={handleChangeDocStatus}
                    >
                      <MenuItem value={"all"} >
                        ทั้งหมด
                      </MenuItem>
                      {statusData.map((item: any) => (
                        <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4.5}>
                  <TextField label="เลขที่จอง" variant="outlined" fullWidth onChange={(event) => setBookingNo(event.target.value)} />
                </Grid>
                <Grid item xs={5.5}>
                  <TextField label="ชื่อลูกค้า" variant="outlined" fullWidth onChange={(event) => setName(event.target.value)} />
                </Grid>
                <Grid item xs={4.5}>
                  <TextField label="เบอร์โทรศัพท์" variant="outlined" fullWidth onChange={(event) => setTel(event.target.value)} />
                </Grid>
                <Grid item xs={5.5}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">เรียงตาม
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={sortValue}
                      label="sortType"
                      onChange={handleChangeSort}
                    >
                      {sortOption.map((option: any, index: number) => (
                        <MenuItem key={`sort-option-${index}`} value={option.variable}>{option.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={2} textAlign={'end'} pb={0.5}>
                  <Button variant="contained" onClick={() => handleSearch(undefined, undefined, undefined, undefined, undefined, true)} startIcon={<SearchIcon />}>ค้นหา</Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={9}>
                      <Typography>ทั้งหมด {total?.toLocaleString()} รายการ</Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <DownloadTableExcel
                        filename={`booking-${dayjs(dateFrom).format('DD-MM-YYYY')} - ${dayjs(dateTo).format('DD-MM-YYYY')}`}
                        sheet={`booking-${dayjs(dateFrom).format('DD-MM-YYYY')} - ${dayjs(dateTo).format('DD-MM-YYYY')}`}
                        currentTableRef={exportRef.current}
                      >
                        <IconButton aria-label="download"  >
                          <DownloadIcon />
                        </IconButton>
                      </DownloadTableExcel>
                    </Grid>
                    <Grid item xs={1}>
                      <ReactToPrint
                        trigger={() => {
                          return (
                            <IconButton aria-label="print">
                              <PrintIcon />
                            </IconButton>
                          );
                        }}
                        content={() => {
                          return printRef.current;
                        }}
                      />
                    </Grid>
                    <Grid item xs={1}>
                      {((permission && permission.create) || authStore.user?.isSuperAdmin) && <Button variant="contained" startIcon={<NoteAddIcon />} onClick={() => navigate('/booking-create')}>สร้าง</Button>}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650, padding: 0, boxSizing: 'border-box' }}>
                      <TableHead>
                        <TableRow>
                          <TableCell>วันที่จอง</TableCell>
                          <TableCell width={100}>เลขที่จอง</TableCell>
                          <TableCell width={150}>ชื่อ-นามสกุล</TableCell>
                          <TableCell>ชื่อโครงการ</TableCell>
                          <TableCell>บ้าน</TableCell>
                          <TableCell>
                            <ul style={{ paddingLeft: '20px', margin: 0 }}>
                              <li>ราคาขาย</li>
                              <li>ค่าจอง</li>
                              <li>ค่าสัญญา</li>
                              <li>ชำระวันโอน</li>
                            </ul>
                          </TableCell>
                          <TableCell width={100}>บันทึก</TableCell>
                          <TableCell width={100}>แก้ไข</TableCell>
                          <TableCell>สถานะ</TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows && rows?.length > 0 && rows.map((row: any, index: number) => {
                          if (!printOneRef.current[index]) {
                            printOneRef.current[index] = createRef();
                          }
                          return (
                            <TableRow
                              key={row.id + index}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                              <TableCell align="center">{dayjs(row?.docDate).format('DD/MM/YYYY') === 'Invalid Date' ? '-' : dayjs(row.docDate).format('DD/MM/YYYY')}</TableCell>
                              <TableCell component="th" scope="row" style={{ textDecoration: row.isDelete ? 'line-through' : 'none' }} >
                                {row.isDelete ? row.bookingNo : <Tooltip title="ข้อมูลนี้ถูกลบแล้ว">{row.bookingNo}</Tooltip>}
                              </TableCell>
                              <TableCell>{row?.customer?.firstname || ''} {row?.customer?.lastname || ''}</TableCell>
                              <TableCell>{row?.project?.name || ''}</TableCell>
                              <TableCell>{row?.house?.name || ''}</TableCell>
                              <TableCell>
                                <ul style={{ paddingLeft: '20px', margin: 0 }}>
                                  <li>{parseFloat(row?.sellingPrice || 0)?.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</li>
                                  <li>{parseFloat(row?.bookingFee || 0)?.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</li>
                                  <li>{parseFloat(row?.contractFee || 0)?.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</li>
                                  <li>{parseFloat(row?.arrearage || 0)?.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</li>
                                </ul>
                              </TableCell>
                              <TableCell>
                                {<b>{row.createBy?.name}</b>} {dayjs(row.createAt).format('DD/MM/YYYY HH:mm')}
                              </TableCell>
                              <TableCell>
                                {<b>{row.updateBy?.name}</b>} {row.updateAt ? dayjs(row.updateAt).format('DD/MM/YYYY HH:mm') : ''}
                              </TableCell>
                              <TableCell> {row?.status?.name && <ChipCustom lable={row?.status?.name} />}</TableCell>
                              <TableCell>
                                <Box display={'flex'} justifyContent={'space-between'}>
                                  <Box component={'span'}>
                                    <ReactToPrint
                                      trigger={() => {
                                        return (
                                          <IconButton aria-label="print">
                                            <PrintIcon />
                                          </IconButton >
                                        );
                                      }}
                                      content={() => {
                                        return printOneRef.current[index]?.current;
                                      }}
                                    />
                                    <Box style={{ display: "none" }}>
                                      <PrintContract ref={printOneRef.current[index]} title={'สัญญาจอง'} data={row} paidTotal={(parseFloat(row.bookingFee || "0") + parseFloat(row.contractFee || "0")).toString()} companyInfo={companyInfo} companyTypes={row?.companyName ? 'person' : 'company'}
                                      />
                                    </Box>
                                  </Box>
                                </Box>
                              </TableCell>
                              <TableCell align="right">
                                <IconButton aria-label="detail" onClick={() => navigate(`/booking-edit/${row.id}`)}>
                                  <KeyboardArrowRightIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          );
                        }
                        )}
                        {!rows && (
                          <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell colSpan={10} align="center">ไม่พบข้อมูล</TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box mt={3}>
                    <TablePagination
                      component="div"
                      rowsPerPageOptions={[10, 25, 50, 100, { label: 'All', value: -1 }]}
                      count={total}
                      onPageChange={(e, newPage) => {
                        setPage(newPage);
                        handleSearch(newPage.toString());
                        localStorage.setItem('page', newPage.toString());
                      }}
                      page={page}
                      rowsPerPage={limit ? limit : 10}
                      onRowsPerPageChange={(e: any) => {
                        setRows([]);
                        setLimit(e.target.value);
                        setPage(0);
                        handleSearch('0', e.target.value.toString());
                        localStorage.setItem('limit', e.target.value.toString());
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <SimpleBackdrop open={isLoading} />
      <Box sx={{ display: "none" }}>
        <PrintBooking ref={printRef} title={'รายการใบจอง'} data={rows} />
      </Box>
      <Box sx={{ display: "none" }}>
        <ExportFileBookings ref={exportRef} title={'รายการใบจอง'} data={rows} />
      </Box>
    </Box >
  );
};

export default Bookings;
